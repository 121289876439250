import React, { Component } from "react";
import style from "./Footer.module.css";
import { Container, Row, Col } from "reactstrap";

class Footer extends Component {
  render() {
    return (
      <div className={style.footer}>
      <footerfoot>
        <Container style={{ textAlign: "center" }}>
          <Col md={{ size: 4, offset: 1 }} sm={12} className={style.management}>
            <h2 className={style.mgmt}>Management </h2>
            <a href="mailto: hrh@vectormgmt.com" style={{ fontSize: "12px" }}>
            hrh@vectormgmt.com
            </a>
          </Col>
          <Col md={{ size: 4, offset: 1 }} sm={12}>
            <h2>Bookings</h2>
            <Row style={{ textAlign: "center" }}>
              <Col md={6} sm={6}>
                <h4>US</h4>
                <a
                  href="mailto: bowen@teamwass.com"
                  style={{ fontSize: "12px" }}
                >
                  bowen@teamwass.com 
                </a>
              </Col>
              <Col md={6} sm={6}>
                <h4>Europe</h4>
                <a
                  href="mailto: paul@primarytalent.com"
                  style={{ fontSize: "12px" }}
                >
                  paul@primarytalent.com
                </a>
              </Col>
            </Row>
          </Col>
        </Container>
      </footerfoot>
        <div style={{ textAlign: "center" }}>
          <p >Copyright © 2024 Valentino Khan</p>
        </div>
      </div>
    );
  }
}

export default Footer;
