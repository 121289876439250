import React, { Component } from "react";
import { Route } from "react-router-dom";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Home from "./components/home/Home";
import Music from "./components/music/Music";
import Tour from "./components/tour/Tour";
import Videos from "./components/videos/Videos";
import "./App.css";

class App extends Component {
  render() {
    return (
      <div className="wrapper">
        <Header />
        <Route exact path="/" render={(props) => <Home {...props} />} />
        <Route path="/music" render={(props) => <Music {...props} />} />
        <Route path="/shows" render={(props) => <Tour {...props} />} />
        <Route path="/videos" render={(props) => <Videos {...props} />} />
        <Footer />
      </div>
    );
  }
}

export default App;
