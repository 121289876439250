import React, { useState } from "react";
import logo from "../../images/logo.png";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Container,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import style from "./Header.module.css";
import MyForm from "./MyForm";
import MailchimpSubscribe from "react-mailchimp-subscribe";

const Header = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [subscribeForm, setSubscribeForm] = useState(false);

  const dropdownToggle = () => setDropdownOpen(!dropdownOpen);
  const toggle = () => setIsOpen((prevState) => !prevState);

  const onMouseEnter = () => {
    setDropdownOpen(true);
  };

  const onMouseLeave = () => {
    setDropdownOpen(false);
  };

  const onSubscribe = () => {
    setSubscribeForm(true);
  };

  return (
    <div>
      <Navbar
        color="white"
        light
        expand="md"
        fixed="top"
        className={subscribeForm ? style.header : style.collapseMenu}
      >
        <NavbarBrand href="/">
          <img src={logo} alt="Logo" className={style.logo} />
        </NavbarBrand>

        <Collapse isOpen={isOpen} navbar>
          <Col md={6} sm={4}>
            <Nav className={`${style.mainNav}`} navbar>
              <Dropdown
                nav
                isOpen={dropdownOpen}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                toggle={dropdownToggle}
              >
                <DropdownToggle
                  tag={Link}
                  to="/music"
                  className={style.nav}
                  nav
                  caret
                >
                  MUSIC
                </DropdownToggle>
                <DropdownMenu style={{ textAlign: "center" }}>
                  <DropdownItem
                    href="https://open.spotify.com/artist/0deIjoDjl9g9Zpw0sCIOHh"
                    target="_blank"
                    className={style.musicLinks}
                  >
                    SPOTIFY
                  </DropdownItem>
                  <DropdownItem
                    href="https://music.apple.com/us/artist/valentino-khan/508730372"
                    target="_blank"
                    className={style.musicLinks}
                  >
                    APPLE MUSIC
                  </DropdownItem>
                  <DropdownItem
                    href="https://www.youtube.com/user/ValentinoKhan?sub_confirmation=1"
                    target="_blank"
                    className={style.musicLinks}
                  >
                    YOUTUBE
                  </DropdownItem>
                  <DropdownItem
                    href="https://music.amazon.com/artists/B007RJUAMK/valentino-khan"
                    target="_blank"
                    className={style.musicLinks}
                  >
                    AMAZON MUSIC
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>

              <NavItem>
                <NavLink
                  onClick={() => {
                    window.location.href = "/shows";
                  }}
                  tag={Link}
                  to="/shows"
                  className={style.nav}
                >
                  SHOWS
                </NavLink>
              </NavItem>
              <NavItem className={style.nav} style={{ marginTop: '14.5px' }} >
                <a href="https://shop.kt8merch.com/collections/valentino-khan" target="_blank" style={{ color: 'black'}}>
                  MERCH
                </a>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/videos" className={style.nav}>
                  VIDEOS
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="https://valentinokhan.com/textsignup" className={style.nav} target="_blank">
                  NEWSLETTER
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Collapse>

        <Col
          xs={7}
          sm={4}
          md={4}
          className={`mr-auto ${style.socialContainer}`}
        >
          <a href="https://www.facebook.com/ValentinoKhan/" target="_blank">
            <img
              src={process.env.PUBLIC_URL + "/images/facebook.png"}
              className={style.socials}
              alt="Facebook"
            />
          </a>
          <a href="https://www.instagram.com/valentinokhan/" target="_blank">
            <img
              src={process.env.PUBLIC_URL + "/images/instagram.png"}
              className={style.socials}
              alt="Instagram"
            />
          </a>
          <a href="https://twitter.com/ValentinoKhan" target="_blank">
            <img
              src={process.env.PUBLIC_URL + "/images/twitter.png"}
              className={style.socials}
              alt="Twitter"
            />
          </a>
          <a href="https://www.tiktok.com/@valentinokhan" target="_blank">
            <img
              src={process.env.PUBLIC_URL + "/images/tiktok.png"}
              className={style.socials}
              alt="TikTok"
            />
          </a>
          <a href="https://www.youtube.com/user/ValentinoKhan?sub_confirmation=1" target="_blank">
            <img
              src={process.env.PUBLIC_URL + "/images/youtube.png"}
              className={style.socials}
              alt="Youtube"
            />
          </a>
          <a
            href="https://open.spotify.com/artist/0deIjoDjl9g9Zpw0sCIOHh"
            target="_blank"
          >
            <img
              src={process.env.PUBLIC_URL + "/images/spotify.png"}
              className={style.socials}
              alt="TikTok"
            />
          </a>
        </Col>
        <NavbarToggler

          onClick={toggle}
          className="primary navbar-toggler"
        />
      </Navbar>
    </div>
  );
};

export default withRouter(Header);
