import React, { Component } from "react";
import style from "./Music.module.css";
import { Container, Col, Row } from "reactstrap";

class Music extends Component {
  render() {
    return (
      <div className={style.music}>
        <Container fluid="xs">
          <Row>
            <Col md={{ size: 4 }} xs={12}>
              <iframe
                className={style.spotify}
                src="https://open.spotify.com/embed/artist/0deIjoDjl9g9Zpw0sCIOHh?utm_source=generator&theme=0"
                width="400"
                height="400"
                frameborder="0"
                allowtransparency="true"
                allow="encrypted-media"
                title="spotify playlist"
              ></iframe>
            </Col>
            <Col
              md={{ size: 4, offset: 3 }}
              xs={12}
              className={`ml-md-auto ${style.soundcloud}`}
            >
            <iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1785002607&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>

              {/* <iframe
                width="100%"
                height="400"
                scrolling="no"
                frameborder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1149367600&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
                title="soundcloud playlist"
              ></iframe> */}
              <div className={style.linkContainer}>
                <a
                  href="https://soundcloud.com/valentinokhan"
                  title="Valentino Khan"
                  target="_blank"
                  className={style.link}
                >
                  Valentino Khan
                </a>{" "}
                ·{" "}
                <a
                  href="https://soundcloud.com/valentinokhan/sets/french-fried-ep"
                  title="🍟 FRENCH FRIED EP 🍟"
                  className={style.playlist}
                  target="_blank"
                >
                  🍟 FRENCH FRIED EP 🍟
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Music;
