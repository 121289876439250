import React, { Component } from "react";
import style from "./Videos.module.css";
import YouTube from "react-youtube";
import { Col, Row } from "reactstrap";

export default class Videos extends Component {
  state = {
    videos: [],
  };

  componentDidMount() {
    this.VideoList();
  }

  VideoList = () => {
    fetch(
      "https://youtube.googleapis.com/youtube/v3/playlistItems?part=snippet%2CcontentDetails&maxResults=25&playlistId=PLEW0y6dbbX_hab0ljBpCKunhPm9UUq_y-&key=AIzaSyBuuoPNM7jY1MmN9SYVY3ydA4FMcxcnPLY"
    )
      .then((resp) => resp.json())
      .then((resp) => {
        let arr = resp.items.filter((item) => item !== resp.items[4]);
        console.log(arr);
        this.setState({ videos: arr });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    const opts = {
      height: "315",
      width: "560",
      // playerVars: {
      //   // https://developers.google.com/youtube/player_parameters
      //   autoplay: 1,
      // },
    };
    return (
      <div className={style.videos}>
        <div className={style.videoContainer}>
          <Row style={{ textAlign: "center" }}>
            {this.state.videos.map((item, i) => {
              return (
                <Col>
                  <h3 className={style.title}>{item.snippet.title}</h3>
                  <YouTube
                    videoId={item.snippet.resourceId.videoId}
                    opts={opts}
                    onReady={this._onReady}
                    className={style.video}
                  />
                </Col>
              );
            })}
            <Col md={6} xs={12}>
              <h3 className={style.title}>
                Valentino Khan - Pump (Official Music Video)
              </h3>
              <iframe
               className={style.video}
                width="560"
                height="315"
                src="https://www.youtube.com/embed/slPqxkqRvqo"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
