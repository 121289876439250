import React, { Component } from "react";
import style from "./Tour.module.css";

class Tour extends Component {
  render() {
    return (
      <div className={style.tour}>
        <div className={style.bandsintown}>
          <a
            className="bit-widget-initializer"
            data-artist-name="valentino khan"
            data-display-local-dates="false"
            data-display-past-dates="false"
            data-auto-style="false"
            data-text-color="#000000"
            data-link-color="#000000"
            data-background-color="rgba(0,0,0,0)"
            data-display-limit="15"
            data-display-start-time="false"
            data-link-text-color="#FFFFFF"
            data-display-lineup="false"
            data-display-play-my-city="true"
            data-separator-color="rgba(255, 255, 255, 0.5)"
          ></a>
        </div>
      </div>
    );
  }
}
export default Tour;
