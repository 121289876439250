import React, { Component } from "react";
import style from "./Home.module.css";
import highpower from "../../images/HighPower.jpeg";

class Home extends Component {
  render() {
    return (
      <div className={style.home}>
        <div className={style.videoContainer}>
        </div>
      </div>
    );
  }
}
export default Home;
